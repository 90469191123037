<template>
    <div class="homeOrder">
        <div class="title">申请售后</div>
        <div class="homeSales">
            <div class="topHead">
                <div class="name">商品名称</div>
                <div class="specifications">商品规格</div>
                <div class="num">购买数量</div>
                <div class="realPay">实付价格</div>
            </div>
            <div class="comt">
                <div class="name">
                    <div class="img">
                        <img :src="mallStoreObjSH.img" alt="">
                    </div>
                    <div class="namee">{{ mallStoreObjSH.productName }}</div>
                </div>
                <div class="specifications">{{ mallStoreObjSH.valueDetailTxt }}</div>
                <div class="num">{{ mallStoreObjSH.pnum }}</div>
                <div class="realPay">¥{{ mallStoreObjSH.paymentMoney }}</div>
            </div>
        </div>

        <div class="modifyTheApplicationFor">
            <div class="title">修改售后类型</div>
            <div class="type">
                <div :class="[i.act ? 'actShow' : '']" @click="typeClick(index)" v-for="(i, index) in typeAfterSalesArr"
                    :key="index">{{ i.name }}</div>
            </div>
            <div class="select">
                <div class="titl">选择退款原因</div>
                <el-select v-model="value" placeholder="请选择" size="mini">
                    <el-option v-for="(i, index) in yunayinList" :key="index" :label="i.label" :value="i.label">
                    </el-option>
                </el-select>
            </div>
            <div class="select">
                <div class="titl">退款金额</div>
                <div class="je">¥{{ mallStoreObjSH.paymentMoney }}</div>
            </div>
            <div class="select">
                <div class="titl">问题描述</div>
                <div class="textarea">
                    <el-input type="textarea" :rows="5" maxlength="200" show-word-limit placeholder="请输入内容"
                        v-model="textarea">
                    </el-input>
                </div>
            </div>
            <div class="select">
                <div class="titl">图片信息</div>
                <div class="upImg">
                    <el-upload :limit="9" action="https://api.cnc-x.cn//mall/uploads" list-type="picture-card"
                        :data="{ type: '12' }" :on-preview="handlePictureCardPreview" :on-remove="handleRemove"
                        :on-change="handleChange" :on-exceed="onExceed">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="">
                    </el-dialog>
                    <div class="texxt">
                        为了帮助您更好的解决问题，请上传图片
                    </div>
                </div>
            </div>
            <div class="select btn_b" @click="requestRefundClick">
                <div class="tn">确认提交</div>
            </div>
        </div>

    </div>
</template>

<script>
import { postRequestRefund } from '@/utils/api/myApi/index'
export default {
    name: 'applyForAfterSales',
    data() {
        return {
            typeAfterSalesArr: [
                {
                    name: '我要退款',
                    act: true,
                },
                {
                    name: '我要退货',
                    act: false,
                }
            ],
            // 退款原因
            yunayinList: [{
                value: '1',
                label: '商品的描述的尺寸与实物不符'
            },
            {
                value: '2',
                label: '做工问题'
            },
            {
                value: '3',
                label: '包装/商品破损/污渍'
            },
            {
                value: '4',
                label: '未按约定时间发货'
            },
            {
                value: '5',
                label: '卖家发错货'
            },
            {
                value: '其他',
                label: '其他'
            }
            ],
            value: '', // 退款原因
            textarea: '', // 问题描述
            dialogImageUrl: '',
            evaluateImage: '', // 图片
            dialogVisible: false,
            mallStoreObjSH: {}, // 售后商品信息 
            mallStoreObjSH2: {},
            returnsStatus: '',
            typeIndex: '1', // 售后类型
        }
    },
    mounted() {
        this.mallStoreObjSH = JSON.parse(localStorage.getItem('mallStoreObjSH'))
        this.mallStoreObjSH2 = JSON.parse(localStorage.getItem('mallStoreObjSH2'))
        if (this.mallStoreObjSH2.otype == 'AGENT') {
            this.returnsStatus = 'agent';
        } else {
            this.returnsStatus = 'common';
        }
        console.log(this.mallStoreObjSH)
    },
    methods: {
        // 提交
        async requestRefundClick() {
            if(!this.value) return this.$message.error('请选择退款原因')
            if(!this.textarea) return this.$message.error('请描述问题原因')
            let data = {
                description: this.textarea,
                detailId: this.mallStoreObjSH.detailId,
                oid: this.mallStoreObjSH.oid,
                refundAmount: this.mallStoreObjSH.paymentMoney,
                refundReson: this.value,
                returnImage: this.evaluateImage,
                returnsStatus: this.returnsStatus,
                status: this.typeIndex,
                storeId: this.mallStoreObjSH2.storeId
            }
            console.log(data) 
            const res = await postRequestRefund(data)
            if (res.data) {
                this.$message.success('操作成功')
                setTimeout(() => {
                    this.$router.push('/myIndex/myAfterSales?actMyNum=1')
                }, 2000);
            } else {
                this.$message.error('操作失败')
            }
        },
        typeClick(index) {
            this.typeAfterSalesArr.map(t => t.act = false)
            this.typeAfterSalesArr[index].act = true
            this.typeIndex = (index + 1) + ''
        },
        handleRemove(file, fileList) {
            console.log(file, fileList, '删除');
            this.handleChange(file, fileList)
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handleChange(file, fileList) {
            console.log(fileList.map(f => f.response?.data).join(','), '变化')
            this.evaluateImage = fileList.map(f => f.response?.data).join(',')
        },
        onExceed(files, fileList) {
            this.$message.error('最多上传9张')
        },
    }
}
</script>

<style lang="less" scoped>
/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
    width: 100%;
    object-fit: cover !important;
}
.homeOrder {
    width: 1050px;
    height: auto;

    >.title {
        width: 100%;
        height: 40px;
        background-color: #fff;
        padding: 10px 15px;
        box-sizing: border-box;
        font-size: 16px;
        color: #333;
        font-weight: bold;
    }

    >.homeSales {
        width: calc(100% - 18px);
        margin-left: 18px;
        height: 133px;
        border: 1px solid #CCCCCC;
        margin-top: 22px;

        >.topHead {
            width: 100%;
            height: 38px;
            display: flex;
            align-items: center;
            text-align: center;
            line-height: 38px;
            color: #333;
            font-size: 14px;

            >.name {
                width: 440px;
                height: 100%;
            }

            >.specifications {
                width: 220px;
                height: 100%;
            }

            >.num {
                width: 160px;
                height: 100%;
            }

            >.realPay {
                width: 217px;
                height: 100%;
            }
        }

        >.comt {
            width: 100%;
            height: 95px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #666666;
            font-size: 14px;
            background-color: #fff;

            >.name {
                width: 440px;
                height: 100%;
                display: flex;
                padding: 15px;
                box-sizing: border-box;
                border-right: 1px solid rgba(151, 151, 151, 0.50);

                >.img {
                    width: 64px;
                    height: 64px;
                    margin-right: 18px;

                    >img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                >.namee {
                    width: 210px;
                    text-align: left;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-height: 2.6rem;
                }
            }

            >.specifications {
                width: 220px;
                height: 100%;
                line-height: 95px;
                border-right: 1px solid rgba(151, 151, 151, 0.50);

            }

            >.num {
                width: 160px;
                line-height: 95px;
                height: 100%;
                border-right: 1px solid rgba(151, 151, 151, 0.50);

            }

            >.realPay {
                width: 217px;
                height: 100%;
                line-height: 95px;
            }
        }

    }



    >.modifyTheApplicationFor {
        width: calc(100% - 18px);
        margin-left: 18px;
        height: auto;
        background-color: #fff;
        padding: 19px 17px;
        box-sizing: border-box;
        margin-top: 20px;

        >.title {
            width: 100%;
            font-size: 14px;
            color: #333333;
        }

        >.type {
            display: flex;
            margin-top: 22px;

            >div {
                width: 127px;
                cursor: pointer;
                height: 32px;
                border-radius: 1px;
                font-size: 14px;
                color: #333333;
                text-align: center;
                line-height: 32px;
                margin-right: 10px;
                border: 1px solid rgba(153, 153, 153, 0.5);
            }
        }

        >.select {
            display: flex;
            margin-top: 20px;

            >.titl {
                font-size: 14px;
                color: #333;
                margin-right: 20px;
            }

            >.je {
                font-size: 14px;
                color: #FF4242;
            }

            >.textarea {
                width: 900px;
            }

            .texxt {
                font-size: 14px;
                color: #999;
                margin-top: 5px;
            }

            >.tn {
                width: 90px;
                height: 30px;
                border-radius: 4px;
                box-sizing: border-box;
                font-size: 12px;
                text-align: center;
                line-height: 30px;
                margin-bottom: 5px;
                cursor: pointer;
                margin-left: 20px;
                background-color: #FF4242;
                color: #fff;
            }

            >.tn2 {
                width: 90px;
                height: 30px;
                border-radius: 4px;
                box-sizing: border-box;
                font-size: 12px;
                text-align: center;
                line-height: 30px;
                margin-bottom: 5px;
                cursor: pointer;
                margin-left: 20px;
                color: #FF4242;
                border: 1px solid #FF4242;
            }
        }

        >.btn_b {
            display: flex;
            justify-content: flex-end;
        }
    }

    >.btn_box {
        width: calc(100% - 18px);
        margin-left: 18px;
        display: flex;
        justify-content: flex-end;
        margin-top: 45px;

        >div {
            width: 90px;
            height: 30px;
            border-radius: 4px;
            box-sizing: border-box;
            font-size: 12px;
            text-align: center;
            line-height: 30px;
            margin-bottom: 5px;
            cursor: pointer;
            margin-left: 20px;
        }

        >.btn {
            background-color: #FF4242;
            color: #fff;
        }

        >.btn2 {
            color: #FF4242;
            border: 1px solid #FF4242;
        }
    }
}

.actShow {
    border: 1px solid #FF4242 !important;
}
</style>